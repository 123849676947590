.main {
  margin: 2rem;
  display: flex;
}

@media all and (min-width: $tablet-width) {
  .main {
    margin-right: 100px;
    margin: 0rem 10rem;
  }
}

@media all and (min-width: $desktop-width) {
  .main__sections {
    padding: 0rem 10rem;
  }
}
