.experience__contain {
  background: $color-white;
  border-radius: 10px;
  // margin-top: 10px;
  margin: 10px 20px;
  padding: 5px;
  //width: 100%;
}

.imageadalab {
  width: 50px;
  margin-right: 10px;
}

.textexperience {
  color: $color-black;
  text-align: justify;
  margin: 10px 0px;
}

.textexperience-list {
  margin: 10px;
  color: $color-black;
  list-style-type: disc;
}

.date {
  color: $color-grey;
}

.adalab {
  display: flex;
}

@media all and (min-width: $tablet-width) {
  .experience {
    margin-right: 5rem;
  }
}
