.hidden {
  display: none;
}

.header {
  padding: 15px;
}

.header__names {
  display: flex;
  align-items: center;
  align-content: center;
}

.lastName {
  margin: 6px 0px 0px 6px;
}

.iconmenu {
  font-size: 30px;
  color: $color-brown;
  position: fixed;
  right: 0;
  top: 0;
  margin: 20px;
}

.menu__mobile {
  background: linear-gradient(180deg, $color-pink 0%, $color-blue 100%);
  right: 0;
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  top: 0;
}

.btnclose {
  cursor: pointer;
}

.iconx {
  color: $color-brown;
  position: absolute;
  right: 0;
  padding: 10px;
  font-size: 1.5rem;
}

.menu__container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
}

.menu_mobilelist {
  padding: 15px;
  text-transform: uppercase;
  text-align: center;
  display: flex;
  flex-direction: column;
}

@media all and (min-width: $tablet-width) {
  .header {
    margin-left: 9rem;
  }

  .iconmenu {
    display: none;
  }

  .menu__mobile {
    display: none;
  }
}

@media all and (min-width: $desktop-width) {
  .header {
    padding: 3% 15%;
  }
}
