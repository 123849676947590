.imageprofile {
  width: 150px;
  height: 250px;
  margin: 10px;
  cursor: pointer;
  border-radius: 20px;
}

.profile__label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: $color-brown;
  padding: 15px 0px;
}

.profile__container {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.container__project {
  cursor: pointer;
}

.container__project:hover .descriptionproj {
  display: flex;
  transform: scale(1.07);
}

.descriptionproj {
  display: none;
  border-radius: 20px;
  position: absolute;
  margin: 10px;
  height: 250px;
  width: 150px;
  align-items: center;
  background: linear-gradient(90deg, $color-pink 0%, $color-blue 100%);
}

@media all and (min-width: $tablet-width) {
  .profile {
    margin-right: 5rem;
  }
  .imageprofile .descriptionproj {
    height: 350px;
    width: 230px;
    padding: 15px;
  }
}
