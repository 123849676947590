* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border-style: none;
  text-decoration: none;
  list-style: none;
  color: white;
}

body {
  background: url("../../images/background.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
